import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class DnlRevenueService implements Resolve<any>
{
    dnlRevenue: any[];
    shipment: any[];
    price: any[];
    unit: any[];
    currency: any[];
    customer: any[];
    country: any[];
    user: any[];
    id;
    shipmentId;
    onDnlRevenueChanged: BehaviorSubject<any>;
    onDataChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
        this.onDnlRevenueChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.id = route.params.id;
        this.shipmentId = route.params.shipmentId;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getData(),
                this.id > 0 ? this.getDnlRevenueById(this.id) : this.getDnlRevenue(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get dnlRevenue
     *
     * @returns {Promise<any>}
     */

    getDnlRevenue(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl/dnl-revenue/id/' + this.shipmentId, httpOptions)
                .subscribe((response: any) => {
                    this.dnlRevenue = response.dnlRevenue;
                    this.shipment = response.shipment;
                    this.onDnlRevenueChanged.next(response.dnlRevenue);
                    resolve(response);
                }, reject);
        });
    }
    getDnlRevenueById(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl/dnl-revenue/shipment/' + id + '/' + this.shipmentId, httpOptions)
                .subscribe((response: any) => {
                    this.dnlRevenue = response.dnlRevenue;
                    this.shipment = response.shipment;
                    this.onDnlRevenueChanged.next(response.dnlRevenue);
                    resolve(response);
                }, reject);
        });
    }
    getData(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl/dnl-revenue/data', httpOptions)
                .subscribe((response: any) => {
                    this.price = response.price;
                    this.unit = response.unit;
                    this.currency = response.currency;
                    this.customer = response.customer;
                    this.country = response.country;
                    this.user = response.user;
                    this.onDataChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }

    createDnlRevenue(dnlRevenue): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/dnl/dnl-revenue', dnlRevenue, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateDnlRevenue(dnlRevenue): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/dnl/dnl-revenue', dnlRevenue, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateMultiDnlRevenue(data): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/dnl/dnl-revenue/multi', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    deleteDnlRevenue(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/dnl/dnl-revenue/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    createDnlOtherIn(dnlOtherIn): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/dnl/dnl-other-in', dnlOtherIn, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateDnlOtherIn(dnlOtherIn): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/dnl/dnl-other-in', dnlOtherIn, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    searchInv(data): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/dnl/dnl-revenue/inv', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    getInvById(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl/dnl-revenue/invId/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
