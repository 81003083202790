import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class DnlBankService implements Resolve<any>
{
    dnlBank: any[];
    account: any[];
    customer: any[];
    onDataChanged: BehaviorSubject<any>;
    onDnlBankChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router,
        private datePipe: DatePipe
    )
    {
        // Set the defaults
        this.onDnlBankChanged = new BehaviorSubject({});
        this.onDataChanged = new BehaviorSubject({});

    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let first = this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd')
        let last = this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd')
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDnlBankData(0, first, last),
                this.getData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getDnlBankData(acc, dateFrom, dateTo): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl-new/dnl-bank/all/' + acc + '/' + dateFrom + '/' + dateTo, httpOptions)
                .subscribe((response: any) => {
                    this.dnlBank = response;
                    this.onDnlBankChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    getData(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/dnl-new/dnl-bank/data', httpOptions)
                .subscribe((response: any) => {
                    this.account = response[0]
                    this.customer = response[1]
                    this.onDataChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    createDnlBank(dnlBank): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/dnl-new/dnl-bank', dnlBank, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateDnlBank(dnlBank): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/dnl-new/dnl-bank', dnlBank, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteDnlBank(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/dnl-new/dnl-bank/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
