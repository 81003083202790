import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class SelVolumeService implements Resolve<any>
{
    selVolume: any[];
    header: [];
    shipment: any[];
    container: any[];
    container2: any[];
    voyage: any[];
    onSelVolumeChanged: BehaviorSubject<any>;
    onVoyageChanged: BehaviorSubject<any>;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onSelVolumeChanged = new BehaviorSubject({});
        this.onVoyageChanged = new BehaviorSubject([]);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVoyageShipment()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get selVolume
     *
     * @returns {Promise<any>}
     */
    getVoyageShipment(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/sel-volume/shipment', httpOptions)
                .subscribe((response: any) => {
                    this.voyage = response;
                    this.onVoyageChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getVoyageShipmentByTime(dateFrom, dateTo): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/sel-volume/date/' + dateFrom + '/' + dateTo, httpOptions)
                .subscribe((response: any) => {
                    this.voyage = response;
                    this.onVoyageChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getVolumeByVoyageId(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/sel-volume/voyage/' + id, httpOptions)
                .subscribe((response: any) => {
                    this.shipment = response.shipment;
                    this.container = response.container;
                    this.onSelVolumeChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getVolumeByVoyageName(vessel_id, voyage_name): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/sel-volume/voyage-name/' + vessel_id + '/' + voyage_name, httpOptions)
                .subscribe((response: any) => {
                    this.shipment = response.shipment;
                    this.header = response.header;
                    this.container = response.container;
                    this.container2 = response.container2;
                    this.onSelVolumeChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getSelVolume(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/sel-volume/all', httpOptions)
                .subscribe((response: any) => {
                    this.selVolume = response;
                    this.onSelVolumeChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }

    createSelVolume(selVolume): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/sel-volume', selVolume, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    createSelVolumeUpdateDocument(data): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/sel-volume/create-document', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateSelVolume(selVolume): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/sel-volume', selVolume, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateSelVolumeUpdateDocument(data): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/sel-volume/update-document', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    deleteSelVolume(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/sel-volume/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteSelVolumeUpdateDocument(data): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/sel-volume/delete-document', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    deleteRow(selVolume): void
    {
        const selVolumeIndex = this.selVolume.indexOf(selVolume);
        this.selVolume.splice(selVolumeIndex, 1);
        this.onSelVolumeChanged.next(this.selVolume);
    }
}
