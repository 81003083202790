import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class LoadService implements Resolve<any>
{
    voyage: any[];
    onDataChanged: BehaviorSubject<any>;
    onLoadChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onLoadChanged = new BehaviorSubject({});
        this.onDataChanged = new BehaviorSubject({});

    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getLoadData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getLoadData(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/movement/load-data', httpOptions)
                .subscribe((response: any) => {
                    this.voyage = response.voyage;
                    this.onDataChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    getContainer(voyageId): Promise<any>{
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/movement/load-container/' + voyageId, httpOptions)
                .subscribe((response: any) => {
                    this.onLoadChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
}
