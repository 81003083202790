import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class AdminService implements Resolve<any>
{
    admin: any[];
    data;
    onAdminChanged: BehaviorSubject<any>;
    onAdminDeleteChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onAdminChanged = new BehaviorSubject({});
        this.onAdminDeleteChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAdmin()
            ]).then(
                () => {
                    resolve();
                },
                reject => {
                  alert(reject.error.error);
                  this.router.navigate(['apps/admin/my-profile']);
                }
            );
        });
    }

    /**
     * Get admin
     *
     * @returns {Promise<any>}
     */
    getAdmin(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/admin', httpOptions)
                .subscribe((response: any) => {
                    this.admin = response;
                    this.onAdminChanged.next(this.admin);
                    resolve(response);
                }, reject);
        });
    }
    getAdminInfo(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/admin/info', httpOptions)
                .subscribe((response: any) => {
                    this.admin = response;
                    this.onAdminChanged.next(this.admin);
                    resolve(response);
                }, reject);
        });
    }
    getAdminProfile(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/admin/profile', httpOptions)
                .subscribe((response: any) => {
                  
                    this.admin = response;
                    this.onAdminChanged.next(this.admin);
                    resolve(response);
                }, reject);
        });
    }
    getProfile(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/admin/profile/'+ this.authService.user, httpOptions)
                .subscribe((response: any) => {
                    this.admin = response;
                    this.onAdminChanged.next(this.admin);
                    resolve(response);
                }, reject);
        });
    }
}
