import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'app/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserSearchService implements Resolve<any>
{
    userSearch: any[];
    onUserSearchChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private authService: AuthService,
    )
    {
        // Set the defaults
        this.onUserSearchChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        let id = route.params.id;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUserSearch(id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getAllUserSearch(): Promise<any>
    {
        let httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Authorization': this.authService.accessToken
                })
            };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/user/user-search/', httpOptions)
                .subscribe((response: any) => {
                    let length = response.length;
                    for(let i = 0; i < length; i++){
                        if(response[i].search != "undefined") response[i].search = JSON.parse(response[i].search);
                    }
                    this.userSearch = response;
                    this.onUserSearchChanged.next(this.userSearch);
                    resolve(response);
                }, reject);
        });
    }
    /**
     * Get userSearch
     *
     * @returns {Promise<any>}
     */
    getUserSearch(id): Promise<any>
    {
        let httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Authorization': this.authService.accessToken
                })
            };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/user/user-search/' + id, httpOptions)
                .subscribe((response: any) => {/*
                    let length = response.length;
                    for(let i = 0; i < length; i++){
                        if(response[i].search != "undefined") response[i].search = JSON.parse(response[i].search);
                    }*/
                    this.userSearch = response;
                    this.onUserSearchChanged.next(this.userSearch);
                    resolve(response);
                }, reject);
        });
    }

}
