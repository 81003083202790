import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class WorkflowService implements Resolve<any>
{
    workflow: any[];
    onWorkflowChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onWorkflowChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getWorkflow()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get workflow
     *
     * @returns {Promise<any>}
     */

    getWorkflow(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/workflow', httpOptions)
                .subscribe((response: any) => {
                    this.workflow = response;
                    this.onWorkflowChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }

    createWorkflow(workflow): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/workflow', workflow, httpOptions)
                .subscribe((response: any) => {
                    this.dataCreate = response;
                    
                    resolve(response);
                }, reject);
        });
    }
    updateWorkflow(workflow): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/workflow', workflow, httpOptions)
                .subscribe((response: any) => {
                    this.dataUpdate = response;
                    
                    resolve(response);
                }, reject);
        });
    }

    deleteWorkflow(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/workflow/' + id, httpOptions)
                .subscribe((response: any) => {
                    
                    this.dataDelete = response;
                    resolve(response);
                }, reject);
        });
    }

    deleteRow(workflow): void
    {
        const workflowIndex = this.workflow.indexOf(workflow);
        this.workflow.splice(workflowIndex, 1);
        this.onWorkflowChanged.next(this.workflow);
    }
}
