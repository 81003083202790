import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class CustomerService implements Resolve<any>
{
    customer;
    users: any[];
    customers: any[];
    country: any[];
    onCustomerChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onCustomerChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        let id = route.params.id;
        return new Promise((resolve, reject) => {

            Promise.all([
                id ? this.getCustomerById(id) : this.getCustomer(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get customer
     *
     * @returns {Promise<any>}
     */

    getCustomer(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/cms/customer', httpOptions)
                .subscribe((response: any) => {
                    this.customers = response.customers;
                    this.country = response.country;
                    this.users = response.users;
                    console.log(this.customers)
                    this.onCustomerChanged.next(this.customers);
                    resolve(response);
                }, reject);
        });
    }
    getCustomerById(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/cms/customer/id/' + id, httpOptions)
                .subscribe((response: any) => {
                    console.log(response);
                    this.customer = response.customer;
                    this.customers = response.customers;
                    this.country = response.country;
                    this.users = response.users;
                    this.onCustomerChanged.next(this.customer);
                    
                    resolve(response);
                }, reject);
        });
    }
    getCustomerByUser(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/customer/user/' + id, httpOptions)
                .subscribe((response: any) => {
                    this.customer = response;
                    this.onCustomerChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    createCustomer(customer): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/cms/customer', customer, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateCustomer(customer): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/cms/customer', customer, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteCustomer(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/cms/customer/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    copyPartner(data)
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/cms/customer/partner/', data, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteRow(customer): void
    {
        const customerIndex = this.customers.indexOf(customer);
        this.customers.splice(customerIndex, 1);
        this.onCustomerChanged.next(this.customers);
    }
}
